/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState, useContext } from "react";
import { Link } from "react-router-dom";

import Logo from "../components/Logo";
import NotAuthorized from "../components/NotAuthorized";

import Select from "../components/Select2";
import Radio from "../components/Radio";
import { UserContext } from "../App";

export default function Settings() {
  const {
    navigation,
    regionOptions,
    groups,
    downloadPoints,
    setDownloadPoints,
    downloadPolygons,
    setDownloadPolygons,
    debugMode,
    setDebugMode,
    signOut,
  } = useContext(UserContext);
  const [language, setLanguage] = useState("English");
  const saveClient = (value) => {
    navigation[0].onChange(value);
    window.localStorage.setItem("client", value);
  };
  const saveCurrentClient = () => {
    let options = Object.keys(navigation[0].options);
    // If there is only one option, the user doesn't hit "saveClient",
    // and we will set the client manually
    // Similarly, if the options do not include the current client coming
    // from localStorage (e.g., when we removed groups for the user), we
    // should also default to the first client
    if (options.length === 1 || !options.includes(navigation[0].value)) {
      let defaultClient = options[0];
      saveClient(defaultClient);
    }
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      {Object.keys(navigation[0].options).length > 0 ? (
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <Logo />

              <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Settings
              </h2>
            </div>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
              <form className="space-y-6" action="#" method="POST">
                {" "}
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Country
                  </label>
                  <div className="mt-2">
                    <Select
                      key={navigation[0].label}
                      label={""}
                      options={
                        navigation[0].label === "Region"
                          ? regionOptions
                          : navigation[0].options
                      }
                      value={navigation[0].value}
                      onChange={(value) => saveClient(value)}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Language
                  </label>
                  <div className="mt-2">
                    <Select
                      key={"language"}
                      label={""}
                      options={["English", "French"]}
                      value={language}
                      onChange={(value) => setLanguage(value)}
                    />
                  </div>
                </div>
                {groups?.includes("admin") && (
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Admin only
                    </label>
                    <div className="mt-2">
                      <Radio
                        texts={"Allow drawing/downloading points"}
                        comments={""}
                        dark={true}
                        checked={downloadPoints}
                        onChange={(value) => setDownloadPoints(!downloadPoints)}
                      />
                    </div>
                    <div className="mt-2">
                      <Radio
                        texts={"Allow drawing/downloading polygons"}
                        comments={""}
                        dark={true}
                        checked={downloadPolygons}
                        onChange={(value) =>
                          setDownloadPolygons(!downloadPolygons)
                        }
                      />
                    </div>
                    <div className="mt-2">
                      <Radio
                        texts={"Debug mode"}
                        comments={""}
                        dark={true}
                        checked={debugMode}
                        onChange={(value) => setDebugMode(!debugMode)}
                      />
                    </div>
                  </div>
                )}
                <div className="pt-4">
                  <Link
                    to="/"
                    onClick={saveCurrentClient}
                    className="flex w-full justify-center rounded-md bg-cyan-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                  >
                    Save
                  </Link>
                </div>
              </form>
              <div className="mt-6 flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-cyan-800">
                <Link to="#" onClick={signOut}>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NotAuthorized signOut={signOut} />
      )}
    </>
  );
}
