import React, { useRef, useEffect, useState, useContext, useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import moment from "moment";
import proj4 from "proj4";

import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import { UserContext } from "../App";
import { fetchData } from "../utils.js";

import mapboxLayerInfo from "./MapboxUtils";
import * as pmtiles from "pmtiles";
import LogoControl from "../ui/control/logo_control.js";
import { fetchAuthSession } from "aws-amplify/auth";
import {
  API_STAGE,
  STAC_STAGE,
  TITILER_STAGE,
  LANDCOVER_LABELS,
  CROPS,
  CROPS_BY_CLIENT,
  PHENOLOGIES,
  PHENOLOGIES_BY_CLIENT,
  CROP_COLORS,
  SEQUENTIAL_COLORS,
  SEQUENTIAL_COLORS_10,
  CLIENTS,
  COUNTRY_BBOXES,
} from "../config/constants.js";
import MyPopover from "./Popover.js";

class DownloadPolygonsControl {
  constructor(draw, name) {
    this.draw = draw;
    this.name = name;
  }
  onAdd(map) {
    this._map = map;

    const button = document.createElement("button");
    button.className = "mapbox-gl-draw_ctrl-draw-btn";
    button.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-4 -4 32 32' stroke-width='1.5' stroke='currentColor' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z' /%3E%3C/svg%3E%0A")`;
    button.addEventListener("click", () => {
      const geojson = this.draw.getAll();
      if (this.name == "polygons") {
        geojson.features = geojson.features.filter(
          (f) => f.geometry.coordinates[0].length > 2,
        );
      } else if (this.name == "points") {
        geojson.features = geojson.features.filter(
          (f) => f.geometry.coordinates.length > 0,
        );
      }
      const blob = new Blob([JSON.stringify(geojson)], {
        type: "application/json",
      });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = this.name + ".geojson";
      a.click();
    });

    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
    this._container.appendChild(button);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

async function updateMinitiles(
  map,
  bbox,
  showS1Tiles,
  showS2Tiles,
  showS2Minitiles,
  showJobStatusRef,
  statsDateRef,
) {
  // Lifted from https://github.com/rasterio/rasterio/blob/0daccc406d9d31bfca975266da9bcbe093a5e62e/rasterio/transform.py#L192
  function array_bounds(height, width, transform) {
    const [a, b, c, d, e, f] = transform;
    if (b == d && b == 0) {
      return [c, f + e * height, c + a * width, f];
    } else {
      const c0x = c;
      const c0y = f;
      const c1x = a * 0 + b * height + c;
      const c1y = d * 0 + e * height + f;
      const c2x = a * width + b * height + c;
      const c2y = d * width + e * height + f;
      const c3x = a * width + b * 0 + c;
      const c3y = d * width + e * 0 + f;
      const xs = [c0x, c1x, c2x, c3x];
      const ys = [c0y, c1y, c2y, c3y];
      return [
        Math.min(...xs),
        Math.min(...ys),
        Math.max(...xs),
        Math.max(...ys),
      ];
    }
  }

  function get_minitile_bboxes(bbox, step_size, patch_size) {
    const [min_x_utm, min_y_utm, max_x_utm, max_y_utm] = bbox;
    const bboxes = [];
    for (let y = max_y_utm; y > min_y_utm; y -= step_size * 10) {
      const bboxes_inner = [];
      for (let x = min_x_utm; x < max_x_utm; x += step_size * 10) {
        bboxes_inner.push([x, y - patch_size * 10, x + patch_size * 10, y]);
      }
      bboxes.push(bboxes_inner);
    }
    return bboxes;
  }

  const s2minitiles = {};
  const s2tiles = {};
  const s1tiles = {};
  if (showS2Tiles || showS2Minitiles) {
    var search_query = {
      datetime: "2024-01-01T00:00:00.000Z/2024-01-31T23:59:59.000Z",
      limit: 1000,
      collections: ["sentinel-2-c1-l2a"],
      fields: {
        include: ["properties", "assets.red", "geometry", "links"],
        exclude: [
          "links",
          "assets.nir-jp2",
          "assets.green-jp2",
          "assets.visual-jp2",
          "assets.aot",
          "assets.red-jp2",
          "assets.tileinfo_metadata",
          "assets.swir16",
          "assets.swir16-jp2",
          "assets.granule_metadata",
          "assets.rededge2-jp2",
          // "assets.red",
          "assets.nir09-jp2",
          "assets.nir",
          "assets.visual",
          "assets.blue-jp2",
          "assets.rededge2",
          "assets.rededge3",
          "assets.rededge1",
          "assets.scl",
          "assets.nir09",
          "assets.coastal-jp2",
          "assets.rededge3-jp2",
          "assets.scl-jp2",
          "assets.nir08",
          "assets.thumbnail",
          "assets.green",
          "assets.nir08-jp2",
          "assets.wvp",
          "assets.wvp-jp2",
          "assets.blue",
          "assets.swir22-jp2",
          "assets.swir22",
          "assets.aot-jp2",
          "assets.rededge1-jp2",
          "assets.coastal",
        ],
      },
    };
    if (bbox !== undefined) {
      search_query["bbox"] = bbox;
    }
    const data = await (
      await fetch("https://earth-search.aws.element84.com/v1/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(search_query),
      })
    ).json();

    for (const item of data.features) {
      if (showS2Tiles) {
        s2tiles[item.id] = item;
      }

      if (showS2Minitiles) {
        const tile_id = item.id.split("_")[1].replace(/^T/, "");
        const proj_shape = item["assets"]["red"]["proj:shape"];
        const proj_transform = item["assets"]["red"]["proj:transform"];
        const bbox = array_bounds(...proj_shape, proj_transform);
        const epsg = item["properties"]["proj:epsg"];
        const crs = await (await fetch(`https://epsg.io/${epsg}.proj4`)).text();

        const bboxes = get_minitile_bboxes(bbox, 1100, 1100);

        for (let i = 0; i < bboxes.length; i++) {
          for (let j = 0; j < bboxes[i].length; j++) {
            const minitile_id = `${tile_id}_${i}_${j}`;
            const xmin = Math.floor((bboxes[i][j][0] - 80) / 10) * 10;
            const ymin = Math.floor((bboxes[i][j][1] - 80) / 10) * 10;
            const xmax = Math.ceil((bboxes[i][j][2] + 80) / 10) * 10;
            const ymax = Math.ceil((bboxes[i][j][3] + 80) / 10) * 10;
            const coords_local = [
              proj4(crs, "EPSG:4326", [xmin, ymin]),
              proj4(crs, "EPSG:4326", [xmax, ymin]),
              proj4(crs, "EPSG:4326", [xmax, ymax]),
              proj4(crs, "EPSG:4326", [xmin, ymax]),
              proj4(crs, "EPSG:4326", [xmin, ymin]),
            ];

            s2minitiles[minitile_id] = {
              type: "Feature",
              properties: {
                minitile_id: minitile_id,
              },
              geometry: {
                type: "Polygon",
                coordinates: [coords_local],
              },
            };
          }
        }
      }
    }
  }

  if (showS1Tiles) {
    var search_query = {
      datetime: "2024-01-01T00:00:00.000Z/2024-01-31T23:59:59.000Z",
      limit: 1000,
      collections: ["sentinel-1-rtc"],
      fields: {
        exclude: ["links", "assets"],
      },
    };
    if (bbox !== undefined) {
      search_query["bbox"] = bbox;
    }
    const data = await (
      await fetch(
        "https://planetarycomputer.microsoft.com/api/stac/v1/search",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(search_query),
        },
      )
    ).json();

    for (const item of data.features) {
      if (showS1Tiles) {
        s1tiles[item.id] = item;
      }
    }
  }

  const geojsonS2Minitiles = {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: {
        name: "urn:ogc:def:crs:OGC:1.3:CRS84",
      },
    },
    features: Object.values(s2minitiles),
  };

  const sourceLayerS2Minitiles = "s2-minitiles";
  if (map.current.getSource(sourceLayerS2Minitiles)) {
    map.current.getSource(sourceLayerS2Minitiles).setData(geojsonS2Minitiles);
  } else {
    map.current.addSource(sourceLayerS2Minitiles, {
      type: "geojson",
      data: geojsonS2Minitiles,
      promoteId: "minitile_id",
    });
    map.current.addLayer(
      {
        id: sourceLayerS2Minitiles + "-layer",
        type: "line",
        source: sourceLayerS2Minitiles,
        paint: {
          "line-color": "#000",
          "line-width": 1,
        },
      },
      "z-index-2",
    );
    map.current.addLayer(
      {
        id: sourceLayerS2Minitiles + "-fill-layer",
        type: "fill",
        source: sourceLayerS2Minitiles,
        paint: {
          "fill-color": "#ff0000",
          "fill-opacity": [
            "case",
            ["!=", ["feature-state", "selected"], null],
            0.2,
            0.0,
          ],
        },
      },
      "z-index-2",
    );
    map.current.on("click", sourceLayerS2Minitiles + "-fill-layer", (e) => {
      const date = statsDateRef.current;
      const coordinates = e.features[0].geometry.coordinates.slice();
      const minitile_id = e.features[0].properties["minitile_id"];
      const c1 = coordinates[0][0];
      const c2 = coordinates[0][2];

      const popUp = new maplibregl.Popup()
        .setLngLat([(c1[0] + c2[0]) / 2, (c1[1] + c2[1]) / 2])
        .setHTML(minitile_id)
        .addTo(map.current);

      if (showJobStatusRef.current) {
        const jobStatusApiUrl = (minitile_id, startDate, endDate) => {
          return `https://${API_STAGE}.api.groundtruthanalytics.com/crop_detection_predict/job_status/?&minitileId=${minitile_id}&startDate=${startDate}&endDate=${endDate}`;
        };
        fetchData(
          jobStatusApiUrl(
            minitile_id + "-crop_detection",
            moment(date).subtract(5, "days").format("YYYY-MM-DD"),
            moment(date).format("YYYY-MM-DD"),
          ),
        ).then((data) => {
          function makeTable() {
            return (
              <div>
                <p>
                  {minitile_id}: {data.count} jobs
                </p>
                {data.count > 0 && (
                  <table>
                    <tr>
                      <th>Scene date</th>
                      <th>Job date</th>
                      <th>Status</th>
                    </tr>
                    {data.items.map((item, i) => {
                      const dates = item.scene_date.split("T00:00:00-");
                      return (
                        <tr key={i}>
                          <td>{dates[0]}</td>
                          <td>{dates[1].split(".")[0]}</td>
                          <td>{item.job_status}</td>
                        </tr>
                      );
                    })}
                  </table>
                )}
              </div>
            );
          }

          popUp.setHTML(renderToStaticMarkup(makeTable()));
        });
      }

      map.current.removeFeatureState({ source: sourceLayerS2Minitiles });
      map.current.setFeatureState(
        { source: sourceLayerS2Minitiles, id: minitile_id },
        { selected: true },
      );
    });
  }

  const geojsonS2Tiles = {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: {
        name: "urn:ogc:def:crs:OGC:1.3:CRS84",
      },
    },
    features: Object.values(s2tiles),
  };

  const sourceLayerS2Tiles = "s2-tiles";
  if (map.current.getSource(sourceLayerS2Tiles)) {
    map.current.getSource(sourceLayerS2Tiles).setData(geojsonS2Tiles);
  } else {
    map.current.addSource(sourceLayerS2Tiles, {
      type: "geojson",
      data: geojsonS2Tiles,
      promoteId: "minitile_id",
    });
    map.current.addLayer(
      {
        id: sourceLayerS2Tiles + "-layer",
        type: "line",
        source: sourceLayerS2Tiles,
        paint: {
          "line-color": "#000",
          "line-width": 3,
        },
      },
      "z-index-2",
    );
  }

  const geojsonS1Tiles = {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: {
        name: "urn:ogc:def:crs:OGC:1.3:CRS84",
      },
    },
    features: Object.values(s1tiles),
  };

  const sourceLayerS1Tiles = "s1-tiles";
  if (map.current.getSource(sourceLayerS1Tiles)) {
    map.current.getSource(sourceLayerS1Tiles).setData(geojsonS1Tiles);
  } else {
    map.current.addSource(sourceLayerS1Tiles, {
      type: "geojson",
      data: geojsonS1Tiles,
      promoteId: "minitile_id",
    });
    map.current.addLayer(
      {
        id: sourceLayerS1Tiles + "-layer",
        type: "line",
        source: sourceLayerS1Tiles,
        paint: {
          "line-color": "#000",
          "line-width": 3,
        },
      },
      "z-index-2",
    );
  }
}

export default function Map({
  currentTab,
  setCurrentTab,
  toggleStats,
  landuseData,
}) {
  const {
    groups,
    client,
    regions,
    regionOptions,
    setRegions,
    getRegionByLevel,
    maxLevel,
    gTAMaxLevel,
    crop,
    showFieldDelineation,
    setShowFieldDelineation,
    activeSatelliteLayer,
    setActiveSatelliteLayer,
    setMapRef,
    date,
    statsDate,
    satelliteLayers,
    setDatesOptions,
    setDate,
    setRegionByLevel,
    debugMode,
    countryBoundary,
    downloadPoints,
    downloadPolygons,
    statsOpen,
    parcelDetail,
    setParcelDetail,
  } = useContext(UserContext);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const statsDateRef = useRef(null);
  const showJobStatusRef = useRef(null);

  const idToken = useRef();
  // Target the span elements used in the sidebar
  const [dataLoaded, setDataLoaded] = useState(false);
  const [regionCentroidData, setRegionCentroidData] = useState([]);

  const countryBoundaryPath = countryBoundary?.assets || 0;
  // const [countryBoundaryPath, setCountryBoundaryPath] = useState("");
  const [catalog, setCatalog] = useState({});
  const [rasterVal, setRasterVal] = useState(null);
  const [coords, setCoords] = useState("");
  const [zoomLevel, setZoomLevel] = useState(0);
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [modifierOpen, setModifierOpen] = useState(false);
  const [list, setList] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(0);

  const [showS1Tiles, setShowS1Tiles] = useState(false);
  const [showS2Tiles, setShowS2Tiles] = useState(false);
  const [showS2Minitiles, setShowS2Minitiles] = useState(false);
  const [showJobStatus, setShowJobStatus] = useState(false);
  const [showActiveRegions, setShowActiveRegions] = useState(false);
  const [showLandcover, setShowLandcover] = useState(false);

  // const datastream = "parcels";
  const datastream =
    crop !== "All" ? "predictions_phenology" : "predictions_crop";
  const sourceLayer = "country-boundary";
  const sourceLayerSelected = "country-boundary-select";

  // Click on a specific layer
  const clickLayer = (item) => {
    if (activeSatelliteLayer?.id !== item?.id) {
      setModifierOpen(!modifierOpen);
    }
    // Update whether the layer selector dropdown is open
    setSelectorOpen(!selectorOpen);

    setActiveSatelliteLayer(item);
  };
  const clickSelector = () => {
    // Hiden the modifiers panel
    if (selectorOpen) {
      setModifierOpen(!modifierOpen);
    } else {
      modifierOpen && setModifierOpen(false);
    }
    // Update whether the layer selector dropdown is open
    setSelectorOpen(!selectorOpen);
  };

  let hoverRegion = null;

  const [fieldOpacity, setFieldOpacity] = useState(0.5);
  const [satelliteOpacity, setSatelliteOpacity] = useState(1.0);
  const [satelliteColorMap, setSatelliteColorMap] = useState(null);

  useEffect(() => {
    if (dataLoaded && debugMode && groups?.includes("admin")) {
      // Add mouseover to show more details for a given layer
      map.current.on("mousemove", "parcels-line-crop", (event) => {
        map.current.getCanvas().style.cursor = "";
        const raster_val = event.features[0].properties.raster_val;
        setRasterVal(raster_val);

        const [lat, lon] = [event.lngLat["lng"], event.lngLat["lat"]];
        setCoords(lat.toFixed(5) + ", " + lon.toFixed(5));
      });
    }
  }, [dataLoaded, debugMode, groups]);

  const regionLineMap = [
    "case",
    ["boolean", ["feature-state", "hover"], false],
    10,
    2,
  ];

  const regionSelectColorMap = [
    "case",
    ["!=", ["feature-state", "selected"], null],
    "#c94d0a",
    "#484896",
  ];

  const get_crop_sequence = (client) => {
    let mappings = CROPS_BY_CLIENT[client]
      ?.slice(1)
      .map((x, index) => [CROPS[x]["id"], CROP_COLORS[index]]);
    var merged = [].concat.apply([], mappings);
    return merged;
  };

  const encode_landcover = (label) => {
    return LANDCOVER_LABELS[label]["id"];
  };

  // Formula from https://github.com/mapbox/mapbox-gl-js/issues/13181
  const get_landcover = (label) => {
    return [
      "%",
      ["floor", ["/", ["get", "landcover"], 1 << encode_landcover(label)]],
      2,
    ];
  };
  const lc_filter = [
    // Show color if we know for sure it's not to be excluded for landcover
    ["==", get_landcover("field"), 1],
    // TODO: once we have tree-type specific model, this might have to change
    // ["==", get_landcover("non_tree"), 1],
    ["==", get_landcover("non_built"), 1],
    ["==", get_landcover("non_water"), 1],
  ];
  const cropColorMap = [
    "case",
    ["all", ["!=", ["get", "crop"], null], ...lc_filter],
    [
      "match",
      ["get", "crop"],
      CROPS["noncroptree"]["id"],
      "rgb(255, 255, 255, 0)",
      ...get_crop_sequence(client),
      "hsla(0, 0%, 0%, 0)",
    ],
    "rgba(255, 255, 255, 0)",
  ];
  const parcelLineMap = [
    "case",
    ["all", ...lc_filter],
    [
      "match",
      ["get", "crop"],
      CROPS["noncroptree"]["id"],
      "rgb(255, 255, 255, 0)",
      "#000000",
    ],
    "rgba(255, 255, 255, 0)",
  ];

  const get_pheno_sequence = (client) => {
    let mappings = PHENOLOGIES_BY_CLIENT[client]?.map((x, index) => [
      PHENOLOGIES[x]["id"],
      SEQUENTIAL_COLORS[index],
    ]);
    var merged = [].concat.apply([], mappings);
    return merged;
  };

  const phenoColorMap = [
    "case",
    [
      "all",
      ["==", ["get", "crop"], CROPS[crop]["id"]],
      ["!=", ["get", "phenology"], null],
      ...lc_filter,
    ],
    [
      "match",
      ["get", "phenology"],
      ...get_pheno_sequence(client),
      "hsla(0.5, 0%, 0%, 0)",
    ],
    "rgba(100, 255, 255, 0)",
  ];

  const regionColorMap = [
    "case",
    ["!=", ["feature-state", "density"], null],
    [
      "match",
      ["feature-state", "density"],
      0,
      SEQUENTIAL_COLORS_10[0],
      1,
      SEQUENTIAL_COLORS_10[1],
      2,
      SEQUENTIAL_COLORS_10[2],
      3,
      SEQUENTIAL_COLORS_10[3],
      4,
      SEQUENTIAL_COLORS_10[4],
      5,
      SEQUENTIAL_COLORS_10[5],
      6,
      SEQUENTIAL_COLORS_10[6],
      7,
      SEQUENTIAL_COLORS_10[7],
      8,
      SEQUENTIAL_COLORS_10[8],
      9,
      SEQUENTIAL_COLORS_10[9],
      10,
      SEQUENTIAL_COLORS_10[10],
      "hsla(0, 0%, 0%, 0)",
    ],
    "rgba(255, 255, 255, 0)",
  ];
  function getCatalog(collection, bbox) {
    var search_query = {
      datetime:
        "2023-01-01T04:00:00.000Z/" +
        moment().add(1, "days").format("YYYY-MM-DD") +
        "T23:59:59.000Z",
      limit: 400,
      collections: [collection],
    };
    if (bbox !== undefined) {
      search_query["bbox"] = bbox;
    }
    fetch(
      "https://stac-server-" + STAC_STAGE + ".groundtruthanalytics.com/search",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(search_query),
      },
    )
      .then((res) => res.json())
      .then((data) => {
        setCatalog(data);
        var dates = data.features.map((f) => {
          return f.properties.start_datetime;
        });
        // Descending
        dates.sort(function (a, b) {
          return new Date(b) - new Date(a);
        });

        var testDate = new Date(statsDate);
        function onlyUnique(value, index, array) {
          return array.indexOf(value) === index;
        }
        dates = dates.filter(onlyUnique);

        var bestDate = statsDate;
        var bestDiff = -new Date(0, 0, 0).valueOf();
        var currDiff = 0;
        var i;

        for (i = 0; i < dates.length; ++i) {
          currDiff = Math.abs(new Date(dates[i]) - testDate);
          if (currDiff < bestDiff) {
            bestDate = dates[i];
            bestDiff = currDiff;
          }
        }
        setDatesOptions(dates);
        setDate(bestDate);
      });
  }

  useEffect(() => {
    (async () => {
      const credentials = await fetchAuthSession();
      if (credentials) {
        idToken.current = credentials.tokens?.idToken;
      }
    })();
  }, []);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    let protocol = new pmtiles.Protocol();
    maplibregl.addProtocol("pmtiles", protocol.tile);

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: {
        version: 8,
        sources: {
          mapbox_raster: {
            type: "raster",
            attribution:
              // using curl "https://api.mapbox.com/v4/mapbox.satellite.json?access_token=MYTOKEN" to get the attribution
              '<a href="https://www.maxar.com/" target="_blank" title="Maxar" aria-label="Maxar">&copy; Maxar</a>',
            tiles: [
              "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.png256?access_token=pk.eyJ1IjoiZGFpc3kxMjMyMSIsImEiOiJjbGhhejhjNW8wbWduM2hvaThvOTgzamdpIn0.sLKvL0-cxWSUp_8awXxiJg",
            ],
          },
          mapbox: {
            type: "vector",
            attribution:
              '<a href="https://www.mapbox.com/about/maps/" target="_blank" title="Mapbox" aria-label="Mapbox">&copy; Mapbox</a> <a href="https://www.openstreetmap.org/about/" target="_blank" title="OpenStreetMap" aria-label="OpenStreetMap">&copy; OpenStreetMap</a> <a class="mapbox-improve-map" href="https://www.mapbox.com/contribute/" target="_blank" title="Improve this map" aria-label="Improve this map">Improve this map</a>',
            tiles: [
              "https://a.tiles.mapbox.com/v4/mapbox.mapbox-streets-v7/{z}/{x}/{y}.vector.pbf?access_token=pk.eyJ1IjoiZGFpc3kxMjMyMSIsImEiOiJjbGhhejhjNW8wbWduM2hvaThvOTgzamdpIn0.sLKvL0-cxWSUp_8awXxiJg",
            ],
          },
        },
        glyphs:
          "https://a.tiles.mapbox.com/v4/fontstack/{fontstack}/{range}.pbf?access_token=pk.eyJ1IjoiZGFpc3kxMjMyMSIsImEiOiJjbGhhejhjNW8wbWduM2hvaThvOTgzamdpIn0.sLKvL0-cxWSUp_8awXxiJg",
        layers: [
          {
            id: "mapbox-tiles",
            type: "raster",
            source: "mapbox_raster",
            minzoom: 0,
            maxzoom: 23,
          },
          ...mapboxLayerInfo,
        ],
      },
      zoom: 6,
      transformRequest: (url, resourceType) => {
        if (
          // resourceType === "Source" &&
          url?.startsWith(
            "https://" +
              API_STAGE +
              ".api.groundtruthanalytics.com/parcels/tiles/",
          )
        ) {
          return {
            url: url,
            headers: { Authorization: idToken.current.toString() },
          };
        }
      },
    });
    map.current.addControl(new maplibregl.ScaleControl());
    map.current.addControl(new LogoControl());

    client &&
      map.current.fitBounds(COUNTRY_BBOXES[CLIENTS[client]["countries"][0]], {
        duration: 0,
      });

    let lastZoom = map.current.getZoom();

    map.current.on("zoom", () => {
      const currentZoom = map.current.getZoom();
      if (lastZoom < 6 && currentZoom >= 6) {
        setZoomLevel(6.1);
      }
      if (lastZoom < 7 && currentZoom >= 7) {
        setZoomLevel(7.1);
      } else if (lastZoom < 9 && currentZoom >= 9) {
        setZoomLevel(9.1);
        map.current.setPaintProperty(
          "country-boundary-select-fill",
          "fill-opacity",
          0,
        );
      } else if (lastZoom < 11 && currentZoom >= 11) {
        setZoomLevel(11.1);
      } else if (lastZoom >= 11 && currentZoom > 11) {
        setZoomLevel(10.9);
      } else if (lastZoom >= 9 && currentZoom < 9) {
        setZoomLevel(8.9);
        map.current.setPaintProperty(
          "country-boundary-select-fill",
          "fill-opacity",
          0.3,
        );
      } else if (lastZoom >= 7 && currentZoom < 7) {
        setZoomLevel(6.9);
      } else if (lastZoom >= 6 && currentZoom < 6) {
        setZoomLevel(5.9);
      }
      lastZoom = currentZoom;
    });

    map.current.on("load", () => {
      // Dummy layers for z-indexing the raster and the vectors above
      map.current.addSource("empty", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });

      map.current.addLayer({
        id: "z-index-3",
        type: "symbol",
        source: "empty",
      });

      map.current.addLayer(
        {
          id: "z-index-2",
          type: "symbol",
          source: "empty",
        },
        "z-index-3",
      );

      map.current.addLayer(
        {
          id: "z-index-1",
          type: "symbol",
          source: "empty",
        },
        "z-index-2",
      );

      // Field delineation and crop detection
      map.current.addSource("parcels", {
        type: "vector",
        attribution:
          '<a href="//www.groundtruthanalytics.com" target="_blank">&copy; Ground Truth Analytics</a>',
        promoteId: "id",
        //        Tegola tile server
        tiles: [
          "https://" +
            API_STAGE +
            ".api.groundtruthanalytics.com/parcels/tiles/maps/" +
            // "http://localhost:8080/maps/" +
            datastream +
            "/{z}/{x}/{y}.pbf?" +
            "client_id=" +
            CLIENTS[client]?.["id"] +
            "&startdate=" +
            moment(statsDate).subtract(9, "days").format("YYYY-MM-DD") +
            "&enddate=" +
            moment(statsDate).add(1, "days").format("YYYY-MM-DD") +
            (datastream === "predictions_crop"
              ? ""
              : "&crop_id=" + CROPS[crop]["id"]),
        ],
      });

      map.current.addLayer(
        {
          id: "parcels-fill-crop",
          type: "fill",
          source: "parcels",
          "source-layer": "predictions_crop",
          minzoom: 9,
          maxzoom: 22,
          paint: {
            "fill-color": cropColorMap,
            "fill-opacity": fieldOpacity,
          },
          layout: {
            visibility: "visible",
          },
        },
        "z-index-2",
      );
      map.current.addLayer(
        {
          id: "parcels-line-crop",
          type: "line",
          source: "parcels",
          "source-layer": "predictions_crop",
          minzoom: 9,
          maxzoom: 22,
          paint: {
            "line-color": parcelLineMap,
            "line-width": 0.3,
          },
          layout: {
            visibility: "visible",
          },
        },
        "z-index-2",
      );

      map.current.on("click", "parcels-fill-crop", (e) => {
        setParcelDetail({
          geometry: {
            type: "Point",
            // First point in the polygon, can easily change to a polygon geometry
            coordinates: e.features[0].geometry.coordinates[0][0],
          },
          properties: e.features[0].properties,
        });
      });

      map.current.addLayer(
        {
          id: "parcels-fill-pheno",
          type: "fill",
          source: "parcels",
          "source-layer": "predictions_phenology",
          minzoom: 9,
          maxzoom: 22,
          paint: {
            "fill-color": "#000000",
            "fill-opacity": fieldOpacity,
          },
          layout: {
            visibility: "none",
          },
        },
        "z-index-2",
      );
      map.current.addLayer(
        {
          id: "parcels-line-pheno",
          type: "line",
          source: "parcels",
          "source-layer": "predictions_phenology",
          minzoom: 9,
          maxzoom: 22,
          paint: {
            "line-color": parcelLineMap,
            "line-width": 0.3,
          },
          layout: {
            visibility: "none",
          },
        },
        "z-index-2",
      );

      map.current.addLayer(
        {
          id: "parcels-fill-landcover",
          type: "fill",
          source: "parcels",
          "source-layer": "predictions_crop",
          minzoom: 9,
          maxzoom: 22,
          paint: {
            "fill-opacity": fieldOpacity,
          },
          layout: {
            visibility: "none",
          },
        },
        "z-index-2",
      );
      map.current.addLayer(
        {
          id: "parcels-line-landcover",
          type: "line",
          source: "parcels",
          "source-layer": "predictions_crop",
          minzoom: 9,
          maxzoom: 22,
          paint: {
            "line-color": "#000000",
            "line-width": 0.3,
          },
          layout: {
            visibility: "none",
          },
        },
        "z-index-2",
      );

      if (downloadPoints) {
        const drawPoint = new MapboxDraw({
          displayControlsDefault: false,
          // Select which mapbox-gl-draw control buttons to add to the map.
          controls: {
            point: true,
            trash: true,
          },
          // Set mapbox-gl-draw to draw by default.
          // The user does not have to click the polygon control button first.
          defaultMode: "draw_point",
        });
        map.current.on("draw.create", () => {
          // Setting the mode back to draw immediately causes the current shape
          // not to finish, so wait a bit
          setTimeout(() => drawPoint.changeMode("draw_point"), 100);
        });
        map.current.addControl(drawPoint);
        map.current.addControl(
          new DownloadPolygonsControl(drawPoint, "points"),
        );
      }

      if (downloadPolygons) {
        const drawPolygon = new MapboxDraw({
          displayControlsDefault: false,
          // Select which mapbox-gl-draw control buttons to add to the map.
          controls: {
            polygon: true,
            trash: true,
          },
          // Set mapbox-gl-draw to draw by default.
          // The user does not have to click the polygon control button first.
          defaultMode: "draw_polygon",
        });
        map.current.on("draw.create", () => {
          // Setting the mode back to draw immediately causes the current shape
          // not to finish, so wait a bit
          setTimeout(() => drawPolygon.changeMode("draw_polygon"), 100);
        });
        map.current.addControl(drawPolygon);
        map.current.addControl(
          new DownloadPolygonsControl(drawPolygon, "polygons"),
        );
      }

      setMapRef(map.current);
      setDataLoaded(true);
    });
  }, []);
  useEffect(() => {
    setShowFieldDelineation(true);
  }, [client]);

  // Update vector tilesets showing predictions when the client changes
  useEffect(() => {
    if (dataLoaded) {
      map.current.getSource("parcels").setTiles([
        // "http://localhost:8080/maps/" +
        "https://" +
          API_STAGE +
          ".api.groundtruthanalytics.com/parcels/tiles/maps/" +
          datastream +
          "/{z}/{x}/{y}.pbf?" +
          "client_id=" +
          CLIENTS[client]?.["id"] +
          "&startdate=" +
          moment(statsDate).subtract(9, "days").format("YYYY-MM-DD") +
          "&enddate=" +
          moment(statsDate).add(1, "days").format("YYYY-MM-DD") +
          (datastream === "predictions_crop"
            ? ""
            : "&crop_id=" + CROPS[crop]["id"]),
      ]);
    }
  }, [client, dataLoaded, crop, statsDate, datastream]);

  // Define mouse events when user click on regions
  // Needs to change as regionOptions get loaded, or user clicks on a different region
  useEffect(() => {
    if (!dataLoaded) return;
    if (!regionOptions) return;

    setSelectedLevel(regions.length);

    const clickRegion = (event) => {
      const regionID = event.features[0].properties["shapeID"];
      if (regions[maxLevel] !== regionID) {
        // if click outside the parent region, need to update
        // all the parents
        function dfs(o, target) {
          if (o.id === target) return [target];
          if (!o.children) return false;
          let path;
          o.children.find((x) => (path = dfs(x, target)));
          if (path) {
            return [o.id].concat(path);
          }
        }
        let path;
        [regionOptions].find((x) => (path = dfs(x, regionID)));
        // Similar to setRegionsByLevel function but doing in one go
        var newRegions = [];
        for (let i = 0; i <= Math.min(maxLevel, path.length - 2); i++) {
          newRegions.push(path[i + 1]);
        }
        setRegions(newRegions);
      }
    };

    const hoverOverRegion = (event) => {
      if (event.features.length > 0) {
        if (hoverRegion !== null) {
          map.current.setFeatureState(
            {
              source: sourceLayer,
              id: hoverRegion,
            },
            {
              hover: false,
            },
          );
        }
        map.current.setFeatureState(
          {
            source: sourceLayer,
            id: event.features[0].id,
          },
          {
            hover: true,
          },
        );
        hoverRegion = event.features[0].id;
      }
    };

    // If the admin turned on download coordinates, we should disable
    // clickRegion because it interferes with mouse-click events
    if (zoomLevel < 9 && !(downloadPoints || downloadPolygons)) {
      map.current.on("click", "country-boundary-fill", clickRegion);
      // TODO: we may show different stats depending on which tab
      if (
        ["Land use", "Crop type", "Phenological stage"].includes(currentTab) &&
        statsOpen
      ) {
        map.current.getSource(sourceLayer) &&
          map.current.setPaintProperty(
            "country-boundary-fill",
            "fill-opacity",
            0.3,
          );
        map.current.getSource(sourceLayerSelected) &&
          map.current.setPaintProperty(
            "country-boundary-select-fill",
            "fill-opacity",
            0.0,
          );
      } else {
        map.current.getSource(sourceLayerSelected) &&
          map.current.setPaintProperty(
            "country-boundary-select-fill",
            "fill-opacity",
            0.3,
          );
        map.current.getSource(sourceLayer) &&
          map.current.setPaintProperty(
            "country-boundary-fill",
            "fill-opacity",
            0.0,
          );
      }

      map.current.on("mousemove", "country-boundary-fill", hoverOverRegion);
      map.current.on("mouseleave", "country-boundary-fill", () => {
        if (hoverRegion !== null) {
          map.current.setFeatureState(
            { source: "country-boundary", id: hoverRegion },
            { hover: false },
          );
        }
        hoverRegion = null;
      });
    }

    if (zoomLevel < 9) {
      map.current.getCanvas().style.cursor = "pointer";
    } else {
      map.current.getCanvas().style.cursor = "";
    }

    return () => {
      map.current.off("click", "country-boundary-fill", clickRegion);
      map.current.off("mousemove", "country-boundary-fill", hoverOverRegion);
    };
  }, [regions, regionOptions, dataLoaded, zoomLevel, statsOpen, currentTab]);

  const getLevelFromZoom = (zoom) => {
    let level = 0;
    if (zoom < 6) {
      level = 1;
    } else if (zoom < 7) {
      level = 1;
    } else if (zoom < 9) {
      level = 2;
    } else if (zoom < 11) {
      level = 3;
    } else {
      level = 4;
    }
    return level;
  };
  const getZoomFromLevel = (level) => {
    let zoom = 0;
    if (level == 0) {
      zoom = 5.5;
    } else if (level == 1) {
      zoom = CLIENTS[client]?.["countries"][0] == "rwanda" ? 8.1 : 7.1;
    } else if (level == 2) {
      zoom = 9.1;
    } else if (level == 3) {
      zoom = 11.1;
    } else {
      zoom = 12.1;
    }
    return zoom;
  };
  // Update the country boundary geojson source when the region level changes
  useEffect(() => {
    if (!countryBoundaryPath) return;
    if (!dataLoaded) return;

    const level = getLevelFromZoom(zoomLevel);
    const geojsonUrl =
      countryBoundaryPath[
        "level" + Math.min(gTAMaxLevel, level) + "_geojson_simplified_us-east-1"
      ].href;
    if (map.current.getSource(sourceLayer)) {
      map.current.getSource(sourceLayer).setData(geojsonUrl);
    } else {
      map.current.addSource(sourceLayer, {
        type: "geojson",
        data: geojsonUrl,
        promoteId: "shapeID",
      });
      map.current.addLayer(
        {
          id: "country-boundary-line",
          type: "line",
          source: sourceLayer,
          sourceLayer: "",
          minzoom: 0,
          maxzoom: 22,
          paint: {
            "line-color": regionSelectColorMap,
            "line-width": regionLineMap,
            "line-opacity": 0.5,
          },
        },
        "z-index-3",
      );
      map.current.addLayer(
        {
          id: "country-boundary-fill",
          type: "fill",
          source: sourceLayer,
          minzoom: 0,
          maxzoom: 22,
          paint: {
            "fill-color": regionColorMap,
            "fill-opacity": 0.0,
          },
        },
        "z-index-1",
      );
    }

    const geojsonUrl2 =
      countryBoundaryPath[
        "level" +
          Math.min(gTAMaxLevel, selectedLevel) +
          "_geojson_simplified_us-east-1"
      ].href;

    if (map.current.getSource(sourceLayerSelected)) {
      map.current.getSource(sourceLayerSelected).setData(geojsonUrl2);
    } else {
      map.current.addSource(sourceLayerSelected, {
        type: "geojson",
        data: geojsonUrl2,
        promoteId: "shapeID",
      });
      map.current.addLayer(
        {
          id: "country-boundary-select-fill",
          type: "fill",
          source: sourceLayerSelected,
          minzoom: 0,
          maxzoom: 22,
          paint: {
            "fill-color": regionSelectColorMap,
            "fill-opacity": 0.3,
          },
        },
        "z-index-2",
      );
    }
  }, [countryBoundaryPath, zoomLevel, selectedLevel, dataLoaded]);

  useEffect(() => {
    if (!dataLoaded) return;
    const country = CLIENTS[client]["countries"][0];
    const geojsonUrl = showActiveRegions
      ? `data/activeregions_${country}.geojson`
      : {
          type: "FeatureCollection",
          features: [],
        };
    const sourceLayer = "country-activeregions";

    if (map.current.getSource(sourceLayer)) {
      map.current.getSource(sourceLayer).setData(geojsonUrl);
    } else {
      map.current.addSource(sourceLayer, {
        type: "geojson",
        data: geojsonUrl,
        promoteId: "shapeID",
      });
      map.current.addLayer({
        id: sourceLayer + "-fill",
        type: "fill",
        source: sourceLayer,
        minzoom: 0,
        maxzoom: 22,
        paint: {
          "fill-color": "red",
          "fill-opacity": 0.3,
        },
      });
    }
  }, [dataLoaded, client, showActiveRegions]);

  // Fly to newly selected region when the region changes
  useEffect(() => {
    if (!countryBoundaryPath) return;
    // if (regionCentroidData.length === 0) return;
    if (!dataLoaded) return;

    // Loop down until we find the correct region
    let level = 0;
    var root = regionOptions;
    if (level === 0 && regions[0] === "All") {
      return;
    } else {
      while (true) {
        if (!root.children) {
          break;
        }
        const matches = root.children.filter((c) => c.id === regions[level]);
        if (regions[level] === "All") {
          break;
        } else if (matches.length > 0 && level < maxLevel) {
          root = matches[0];
          level++;
        } else {
          break;
        }
      }
    }

    var newLocation = root?.centroid || [0, 0];
    var newZoom = getZoomFromLevel(maxLevel);

    setZoomLevel(newZoom);
    map.current.flyTo({
      center: newLocation,
      zoom: newZoom,
    });
  }, [countryBoundaryPath, maxLevel, regions, dataLoaded]);

  // Highlight the child regions selected
  // We can't just highlight the selected region because the geojson shows the next level
  useEffect(() => {
    if (!regionOptions) return;
    if (!dataLoaded) return;

    // get the regions at the level of geojson that are children of the selected regions
    const getRegions = (root, l, level) => {
      if (root.children) {
        if (l === level) {
          return [root];
        }
        const regionName = getRegionByLevel(l);
        if (regionName === "All") {
          let children = [];
          root.children.forEach((c) => {
            children = children.concat(getRegions(c, l + 1, level));
          });
          return children;
        } else {
          let matches = root.children.filter((c) => c.id === regionName);
          if (matches.length > 0) {
            return getRegions(matches[0], l + 1, level);
          }
        }
      } else {
        return [];
      }
    };
    const level = getLevelFromZoom(zoomLevel);
    const children = getRegions(
      regionOptions,
      0,
      Math.max(selectedLevel, level),
    );

    function setStates(source) {
      // We need to remove the Feature State, otherwise lingering regions would still be highlighted
      map.current.removeFeatureState({
        source: source,
      });
      let maxCount = 0;
      children.forEach((c) => {
        let count = landuseData?.[c["id"]]?.["count"];
        if (count > maxCount) {
          maxCount = count;
        }
      });
      children.forEach((c) => {
        map.current.setFeatureState(
          {
            source: source,
            sourceLayer: "",
            id: c["id"],
          },
          {
            density: Math.floor(
              (landuseData?.[c["id"]]?.["count"] / maxCount) * 10,
            ),
            selected: true,
          },
        );
      });
    }

    // Check if `country-boundary` source is loaded.
    function setAfterLoad(event) {
      if (event.sourceID !== sourceLayer && !event.isSourceLoaded) return;
      setStates(sourceLayer);
      map.current.off("sourcedata", setAfterLoad);
    }
    // If `country-boundary` source is loaded, call `setStates()`.
    if (map.current.isSourceLoaded(sourceLayer)) {
      setStates(sourceLayer);
    } else {
      map.current.on("sourcedata", setAfterLoad);
    }

    // For showing selected region, we do not need to loop over the children because
    // the geojson for the country-boundary-select is at the same level as the selection
    function setStates2(source) {
      map.current.removeFeatureState({
        source: source,
      });

      map.current.setFeatureState(
        {
          source: source,
          sourceLayer: "",
          id: regions[regions.length - 1],
        },
        {
          selected: true,
        },
      );
    }
    function setAfterLoad2(event) {
      if (event.sourceID !== sourceLayerSelected && !event.isSourceLoaded)
        return;
      setStates2(sourceLayerSelected);
      map.current.off("sourcedata", setAfterLoad2);
    }
    if (map.current.isSourceLoaded(sourceLayerSelected)) {
      setStates2(sourceLayerSelected);
    } else {
      map.current.on("sourcedata", setAfterLoad2);
    }

    // Add mouseover to show more details for a given layer
  }, [countryBoundary, regions, dataLoaded, zoomLevel, landuseData]);

  function updateLegend(layer, setColorMap) {
    if (layer.colorMap?.colorMapName) {
      const awsRegion = layer.region;
      const server =
        "https://gta-titiler-" +
        awsRegion +
        "-lambda-" +
        TITILER_STAGE +
        ".groundtruthanalytics.com";
      const url =
        server + "/colorMaps/" + layer.colorMap.colorMapName.toLowerCase();
      fetch(url)
        .then((r) => r.json())
        .then(setColorMap);
    } else {
      setColorMap(null);
    }
  }

  // Update the raster shown. Is called when catalog or date changes
  function updateLayer(layer, showFlag) {
    const awsRegion = layer.region;
    const sourceLayer = layer.id + "-tilesets";

    if (Object.keys(catalog).length === 0) {
      return;
    }

    const match = catalog.features.find(
      ({ collection, properties }) => properties.start_datetime === date,
    );
    if (match === undefined) {
      return;
    }
    var url = match.links[0].href;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        // TODO: change to this to when we load the country from catalog
        const country_shape =
          countryBoundaryPath?.["level0_geojson_simplified_" + awsRegion]?.href;

        if (dataLoaded) {
          // Construct the base URL for the tiler
          const server =
            "https://gta-titiler-" +
            awsRegion +
            "-lambda-" +
            TITILER_STAGE +
            ".groundtruthanalytics.com";
          // const server = "http://localhost:8000";
          const url =
            server +
            "/mosaic/" +
            layer.satellite +
            "/tiles/WebMercatorQuad/{z}/{x}/{y}@2x";

          // Construct the query string
          const query = new URLSearchParams(layer.params);
          // URL of mosaic
          query.append("url", data.assets[awsRegion].href);
          // Clip to country geometry
          query.append("algorithm", "clip_to_geometry");
          query.append(
            "algorithm_params",
            JSON.stringify({
              geometry: country_shape,
            }),
          );
          // Configure colormap
          if (layer.colorMap?.min !== undefined) {
            const offset = layer.colorMap?.offset || 0;
            query.append("rescale", [
              layer.colorMap.min + offset,
              layer.colorMap.max + offset,
            ]);
          }
          if (layer.colorMap?.colorMapName) {
            query.append(
              "colormap_name",
              layer.colorMap.colorMapName.toLowerCase(),
            );
          }

          const new_tiles = [url + "?" + query.toString()];

          if (map.current.getSource(sourceLayer)) {
            map.current.getSource(sourceLayer).tiles = new_tiles;
            map.current.style.sourceCaches[sourceLayer].clearTiles();
            map.current.style.sourceCaches[sourceLayer].update(
              map.current.transform,
            );
            map.current.triggerRepaint();
          } else {
            map.current.addSource(sourceLayer, {
              type: "raster",
              attribution: ["sentinel-1", "sentinel-2", "ndvi"].includes(layer)
                ? '<a href="https://www.copernicus.eu/en.com" target="_blank">&copy; Copernicus Sentinel data [' +
                  date.substr(0, 4) +
                  "]</a>"
                : "",
              tiles: new_tiles,
            });
            map.current.addLayer(
              {
                id: sourceLayer + "-layer",
                type: "raster",
                source: sourceLayer,
                minzoom: 0,
                maxzoom: 22,
                paint: {
                  "raster-opacity": 1,
                },
                layout: {
                  visibility: showFlag ? "visible" : "none",
                },
              },
              "z-index-1",
            );
          }
        }
      });
  }

  useEffect(() => {
    if (dataLoaded && countryBoundaryPath) {
      satelliteLayers.forEach((layer) =>
        updateLayer(layer, layer.id === activeSatelliteLayer?.id),
      );
    }
  }, [dataLoaded, date, countryBoundaryPath]);

  useEffect(() => {
    if (dataLoaded) {
      map.current.setPaintProperty(
        "parcels-fill-pheno",
        "fill-color",
        phenoColorMap,
      );
    }
  }, [dataLoaded, crop]);

  const changeVisibility = (showFlag, layer) => {
    if (dataLoaded && map.current.getLayer(layer)) {
      if (showFlag) {
        map.current.setLayoutProperty(layer, "visibility", "visible");
      } else {
        map.current.setLayoutProperty(layer, "visibility", "none");
      }
    }
  };

  function updateCatalog(collection) {
    const currentBound = map.current.getBounds();

    const sw = currentBound._sw;
    const ne = currentBound._ne;

    let bbox = [sw["lng"], sw["lat"], ne["lng"], ne["lat"]];
    getCatalog(collection, bbox);
  }

  useEffect(() => {
    if (dataLoaded) {
      const currentBound = map.current.getBounds();

      const sw = currentBound._sw;
      const ne = currentBound._ne;

      let bbox = [sw["lng"], sw["lat"], ne["lng"], ne["lat"]];

      statsDateRef.current = statsDate;
      showJobStatusRef.current = showJobStatus;
      updateMinitiles(
        map,
        bbox,
        showS1Tiles,
        showS2Tiles,
        showS2Minitiles,
        showJobStatusRef,
        statsDateRef,
      );
    }
  }, [
    showS1Tiles,
    showS2Tiles,
    showS2Minitiles,
    showJobStatus,
    dataLoaded,
    statsDate,
  ]);

  useEffect(() => {
    changeVisibility(false, "parcels-fill-crop");
    changeVisibility(false, "parcels-line-crop");
    changeVisibility(false, "parcels-fill-pheno");
    changeVisibility(false, "parcels-line-pheno");
    changeVisibility(false, "parcels-fill-landcover");
    changeVisibility(false, "parcels-line-landcover");

    if (showFieldDelineation) {
      if (showLandcover !== false) {
        // Show landcover
        changeVisibility(true, "parcels-fill-landcover");
        changeVisibility(true, "parcels-line-landcover");
        if (dataLoaded && map.current.getLayer("parcels-fill-landcover")) {
          const lcMap = [
            "case",
            [
              "all",
              ...Object.entries(showLandcover)
                .filter(([_, v]) => v)
                .map(([k, _]) => ["==", get_landcover(k), 1]),
            ],
            "#229954",
            "#bbbbbb",
          ];
          map.current.setPaintProperty(
            "parcels-fill-landcover",
            "fill-color",
            lcMap,
          );
        }
      } else if (datastream === "predictions_phenology") {
        // Show phenological stage
        changeVisibility(true, "parcels-fill-pheno");
        changeVisibility(true, "parcels-line-pheno");
      } else {
        // Show crop detection
        changeVisibility(true, "parcels-fill-crop");
        changeVisibility(true, "parcels-line-crop");
      }
    }
  }, [showFieldDelineation, showLandcover, datastream]);

  useEffect(() => {
    satelliteLayers.forEach((layer) => {
      const show = layer.id === activeSatelliteLayer?.id;
      if (show) {
        updateCatalog("mosaics-" + client + "-" + layer.satellite);
        updateLayer(layer, true);
        updateLegend(layer, setSatelliteColorMap);
        setSatelliteOpacity(1.0);
      }
      changeVisibility(show, layer.id + "-tilesets-layer");
    });
  }, [activeSatelliteLayer, client]);

  return (
    <div className="relative">
      <MyPopover
        dataLoaded={dataLoaded}
        fieldOpacity={fieldOpacity}
        setFieldOpacity={setFieldOpacity}
        satelliteOpacity={satelliteOpacity}
        setSatelliteOpacity={setSatelliteOpacity}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        zoomLevel={zoomLevel}
        toggleStats={toggleStats}
        selectorOpen={selectorOpen}
        modifierOpen={modifierOpen}
        showS1Tiles={showS1Tiles}
        setShowS1Tiles={setShowS1Tiles}
        showS2Tiles={showS2Tiles}
        setShowS2Tiles={setShowS2Tiles}
        showS2Minitiles={showS2Minitiles}
        setShowS2Minitiles={setShowS2Minitiles}
        showJobStatus={showJobStatus}
        setShowJobStatus={setShowJobStatus}
        showActiveRegions={showActiveRegions}
        setShowActiveRegions={setShowActiveRegions}
        showLandcover={showLandcover}
        setShowLandcover={setShowLandcover}
        clickLayer={clickLayer}
        clickSelector={clickSelector}
        list={list}
        satelliteColorMap={satelliteColorMap}
      />
      <div ref={mapContainer} className="inset-0 map-container"></div>
      {showFieldDelineation && dataLoaded && (
        <div className="hidden sm:block fixed bottom-4 right-0">
          <span className="text-left inline-flex w-full justify-center gap-x-1.5 px-2 py-2 text-xs font-medium text-gray-900 flex flex-col">
            {rasterVal !== null && (
              <div>
                <strong id="crop-identifier">Field:</strong>
                <span>{rasterVal}</span>
              </div>
            )}
            {coords !== "" && (
              <div>
                <strong id="crop-identifier">Position:</strong>
                <span>{coords}</span>
              </div>
            )}
          </span>
        </div>
      )}
    </div>
  );
}
