import { Fragment, useContext } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../App";

export default function Slideover({
  statsOpen,
  setStatsOpen,
  client,
  regions,
  maxLevel,
  crop,
  children,
  getRegionNameByLevel,
  regionOptions,
}) {
  const { navigation } = useContext(UserContext);

  return (
    <>
      <Transition.Root show={statsOpen} as={Fragment}>
        <div className="relative z-20" onClose={setStatsOpen}>
          <div className="fixed inset-x-0">
            <div className="absolute inset-x-0 w-full">
              <div className="pointer-events-none fixed inset-y-0 top-12 right-0 flex pl-10 max-w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="pointer-events-auto flex-1">
                    <div className="flex h-full flex-col overflow-y-scroll bg-gray-900 bg-opacity-80 py-4 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="ml-2 text-base font-semibold leading-6 text-white">
                            {"Statistics " +
                              (maxLevel === 0
                                ? " for " + navigation[0]["options"][client]
                                : " for " +
                                  getRegionNameByLevel(
                                    regionOptions,
                                    maxLevel - 1,
                                  )) +
                              (crop === "All" ? "" : " for " + crop)}
                          </div>
                          <div className="ml-3 flex h-4 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-gray-900 bg-opacity-80 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              onClick={() => setStatsOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex-1 px-4 sm:px-6">
                        {children}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </div>
        </div>
      </Transition.Root>
    </>
  );
}
