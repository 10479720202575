import { useEffect, useState, Fragment } from "react";
import "chartjs-adapter-moment";
import moment from "moment";

import Table from "../components/Table.js";
import { Barchart } from "../components/Barchart.js";
import { CLIENTS } from "../config/constants.js";

import { Line } from "react-chartjs-2";
import { fetchData } from "../utils.js";
import { CROPS, PHENOLOGIES, API_STAGE } from "../config/constants.js";

import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LogarithmicScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const CROPMAP = Object.fromEntries(
  Object.entries(CROPS).map(([k, v]) => [v["id"], k]),
);
const PHENOMAP = Object.fromEntries(
  Object.entries(PHENOLOGIES).map(([k, v]) => [v["id"], k]),
);

function ParcelDetailTable({ tableContent }) {
  return (
    <div className="mt-4 px-2 flow-root">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6">
          <table className="min-w-full divide-y divide-gray-300">
            <tbody className="divide-y divide-gray-200">
              {Object.keys(tableContent).map((k) => {
                return (
                  <tr key={k} className="odd:bg-gray-50">
                    <td className="px-3 whitespace-nowrap py-2 text-sm text-gray-500 font-semibold">
                      {tableContent[k][0]}
                    </td>
                    <td className="px-3 whitespace-nowrap py-2 text-sm text-gray-500">
                      {tableContent[k][1]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function BarplotNDVI(bardataNDVI) {
  return (
    bardataNDVI["datasets"].length > 0 && (
      <div
        className="mx-auto mt-6"
        style={{ maxWidth: "450px", height: "250px" }}
      >
        <Line
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                type: "time",
                time: {
                  unit: "day",
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: true,
                text: "NDVI",
              },
            },
          }}
          data={bardataNDVI}
        />
      </div>
    )
  );
}

function BarplotWeather(
  barPrecipitation,
  barTemperatureDay,
  barTemperatureNight,
  DateRangeToggle,
) {
  const bardataWeather = {
    datasets: [
      {
        type: "bar",
        label: "Precipitation",
        data: barPrecipitation?.map((b) => {
          return {
            x: new Date(b.scene_datetime_start),
            y: b.value,
          };
        }),
        yAxisID: "y1",
        backgroundColor: "#00000066",
        barPercentage: 0.4,
      },
      {
        type: "line",
        label: "Day temperature",
        data: barTemperatureDay
          ?.filter((d) => {
            return !!d.value;
          })
          .map((b) => {
            return {
              x: new Date(b.scene_datetime_start),
              y: b.value * 0.02 - 273.15,
            };
          })
          .sort(function (first, second) {
            return second.x - first.x;
          }),
        borderColor: "#d9770680",
        backgroundColor: "#d9770680",
        yAxisID: "y",
      },
      {
        type: "line",
        label: "Night temperature",
        data: barTemperatureNight
          ?.filter((d) => {
            return !!d.value;
          })
          .map((b) => {
            return {
              x: new Date(b.scene_datetime_start),
              y: b.value * 0.02 - 273.15,
            };
          })
          .sort(function (first, second) {
            return second.x - first.x;
          }),
        borderColor: "#0891b280",
        backgroundColor: "#0891b280",
        yAxisID: "y",
      },
    ],
  };

  return (
    bardataWeather["datasets"].length > 0 && (
      <>
        <div className="pt-2">
          <DateRangeToggle />
        </div>
        {bardataWeather.length === 0 ? (
          <div className="mt-4 text-center text-gray-500 text-sm">
            <h2 className="mt-6 font-semibold">No Data Available</h2>
            <p>Please select a different date range or check back later.</p>
          </div>
        ) : (
          <>
            <div
              className="mx-auto mt-6"
              style={{ maxWidth: "450px", height: "250px" }}
            >
              <Chart
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        unit: "day",
                      },
                    },
                    y: {
                      type: "linear",
                      display: true,
                      position: "left",
                    },
                    y1: {
                      type: "linear",
                      display: true,
                      position: "right",
                      grid: {
                        drawOnChartArea: false,
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: true,
                    },
                    title: {
                      display: true,
                      text: "Temperature",
                    },
                  },
                }}
                data={bardataWeather}
              />
            </div>
          </>
        )}
      </>
    )
  );
}

const generateNDVIData = (parcelData) => {
  // Remove duplicate values on the same date
  var items = {};
  parcelData["items"].forEach((x) => {
    let date = moment(x["timestamp"]).format("YYYY-MM-DD");
    items[date] = x;
  });

  return {
    datasets: [
      {
        labels: "NDVI",
        data:
          Object.keys(items).map((key) => ({
            x: items[key]["timestamp"],
            y: items[key]["value"],
          })) || [],
        borderColor: "#0891b280",
        backgroundColor: "#0891b280",
      },
    ],
  };
};

const generateWeatherBarData = (parcelData) => {
  return parcelData?.["items"].map(
    (item) =>
      ({
        scene_datetime_start: item["timestamp"],
        value: item["value"],
      } || []),
  );
};

export default function Statistics({
  client,
  crop,
  statsDate,
  statsOpen,
  regions,
  getRegionByLevel,
  crops,
  currentTab,
  setCurrentTab,
  showFieldDelineation,
  showPhenologicalStage,
  data,
  dataByCropForTable,
  dataByPhenologyForTable,
  dataForTable,
  bardata,
  bardataCrop,
  bardataPheno,
  bardataNDVI,
  barPrecipitation,
  barTemperatureDay,
  barTemperatureNight,
  totalArea,
  parcelCount,
  DateRangeToggle,
  parcelDetail,
  startDate,
  endDate,
}) {
  function setCurrent(name) {
    setCurrentTab(name);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const tabs = [
    { name: "Land use" },
    { name: "Crop type" },
    { name: "Phenological stage" },
    { name: "Weather and Indices" },
    { name: "Yield" },
  ];

  const [parcelPheno, setParcelPheno] = useState(null);
  const [parcelBardataNDVI, setParcelBardataNDVI] = useState({ datasets: [] });
  const [parcelBardataTemperatureDay, setParcelBardataTemperatureDay] =
    useState([]);
  const [parcelBardataTemperatureNight, setParcelBardataTemperatureNight] =
    useState([]);
  const [parcelBardataPrecipitation, setParcelBardataPrecipitation] = useState(
    [],
  );
  const [showParcelLevelStats, setShowParcelLevelStats] = useState(false);

  useEffect(() => {
    if (showParcelLevelStats) {
      const parcelId = parcelDetail?.["properties"]?.["id"];
      if (parcelId === undefined) {
        return;
      }

      const parcelApiUrl = (pkey, startDate, endDate, region) => {
        startDate = moment(startDate).format("YYYY-MM-DDTHH:mm:ss[Z]");
        endDate = moment(endDate).format("YYYY-MM-DDTHH:mm:ss[Z]");
        return `https://${API_STAGE}.api.groundtruthanalytics.com/parcels/details/?datastream=${pkey}&client=${client}&startDate=${startDate}&endDate=${endDate}&parcelId=${parcelId}`;
      };

      fetchData(
        parcelApiUrl("phenological_stage_wheat", startDate, endDate),
      ).then((data) => {
        setParcelPheno(data?.["items"]?.[0]?.["phenology"] || "");
      });

      fetchData(parcelApiUrl("ndvi", startDate, endDate)).then((data) => {
        setParcelBardataNDVI(generateNDVIData(data));
      });

      fetchData(parcelApiUrl("temperature_day_daily", startDate, endDate)).then(
        (data) => {
          setParcelBardataTemperatureDay(generateWeatherBarData(data));
        },
      );

      fetchData(
        parcelApiUrl("temperature_night_daily", startDate, endDate),
      ).then((data) => {
        setParcelBardataTemperatureNight(generateWeatherBarData(data));
      });

      fetchData(parcelApiUrl("rainfall_daily", startDate, endDate)).then(
        (data) => {
          setParcelBardataPrecipitation(generateWeatherBarData(data));
        },
      );
    }
  }, [parcelDetail, statsDate, startDate, endDate]);

  const parcelCrop = parcelDetail?.["properties"]?.["crop"];
  const tableContent = {
    crop: ["Crop", CROPMAP[parcelCrop] || parcelCrop],
    pheno: ["Phenological stage", parcelPheno],
    date: ["Date", parcelDetail?.["properties"]?.["created_at"] || statsDate],
  };

  return (
    <div className="mt-4 bg-white overflow-scroll shadow rounded-lg divide-y divide-gray-200 col-span-4">
      {/*      <div className="mt-2 p-2 flex items-center justify-center w-full">
        <button
          type="button"
          onClick={() => setShowParcelLevelStats(!showParcelLevelStats)}
          className="flex-grow bg-cyan-800 bg-opacity-80 p-2 rounded-md text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          {showParcelLevelStats ? "Show aggregate" : "Show parcel level"}
        </button>
      </div>*/}
      <div className="px-2 py-2 sm:px-6">
        {showParcelLevelStats ? (
          <div className="mt-5 border-gray-200 grid grid-cols-1">
            <div className="px-2 divide-y divide-gray-200 max-w-md">
              {parcelDetail && (
                <div className="py-4 w-96 max-w-full mx-auto">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-gray-900">
                        Parcel Details
                      </h1>
                    </div>
                  </div>
                  <ParcelDetailTable tableContent={tableContent} />
                </div>
              )}
              {parcelDetail?.properties?.images1 && (
                <img
                  className="max-w-sm"
                  src={
                    "https://gtdatacollection-images.s3.eu-central-1.amazonaws.com/" +
                    parcelDetail.properties.images1
                  }
                />
              )}
              <div className="sm:items-center py-6">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">
                    Weather and Indices
                  </h1>
                </div>
                {BarplotWeather(
                  parcelBardataPrecipitation,
                  parcelBardataTemperatureDay,
                  parcelBardataTemperatureNight,
                  DateRangeToggle,
                )}
                {BarplotNDVI(parcelBardataNDVI)}
              </div>
              <div className="sm:items-center pt-2">
                <a
                  href="https://data.groundtruthanalytics.com/"
                  target="_blank"
                  title="DataCollection"
                  aria-label="DataCollection"
                  className="items-center"
                >
                  <span className="px-3 whitespace-nowrap py-2 text-sm text-gray-500">
                    Link to raw data
                  </span>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="border-b border-gray-200 pb-5 sm:pb-0">
              <div className="mt-3 sm:mt-4">
                <div className="sm:hidden">
                  <label htmlFor="current-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="current-tab"
                    name="current-tab"
                    onChange={(e) => setCurrent(e.target.value)}
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                    defaultValue={tabs[0].name}
                  >
                    {tabs.map((tab) => {
                      if (
                        !showPhenologicalStage &&
                        tab.name === "Phenological stage"
                      ) {
                        return <Fragment key={tab.name}></Fragment>;
                      } else if (
                        showPhenologicalStage &&
                        tab.name === "Crop type"
                      ) {
                        return <Fragment key={tab.name}></Fragment>;
                      }
                      return <option key={tab.name}>{tab.name}</option>;
                    })}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => {
                      if (
                        !showPhenologicalStage &&
                        tab.name === "Phenological stage"
                      ) {
                        return <Fragment key={tab.name}></Fragment>;
                      } else if (
                        showPhenologicalStage &&
                        tab.name === "Crop type"
                      ) {
                        return <Fragment key={tab.name}></Fragment>;
                      }
                      return (
                        <button
                          key={tab.name}
                          onClick={() => setCurrent(tab.name)}
                          className={classNames(
                            tab.name === currentTab
                              ? "border-cyan-500 text-cyan-600"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "whitespace-nowrap border-b-2 px-1 pb-2 text-sm font-medium",
                          )}
                          aria-current={
                            tab.name === currentTab ? "page" : undefined
                          }
                        >
                          {tab.name}
                        </button>
                      );
                    })}
                  </nav>
                </div>
              </div>
            </div>
            <div className="mt-5 border-gray-200 grid grid-cols-1">
              <div className="px-2 divide-y divide-gray-200">
                {currentTab === "Land use" && (
                  <>
                    <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-5">
                      <dt className="text-sm font-medium text-gray-500">
                        Total area (Ha)
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0">
                        <span className="flex-grow">
                          {totalArea.toLocaleString()}
                        </span>
                      </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-5">
                      <dt className="text-sm font-medium text-gray-500">
                        Number of parcels
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0">
                        <span className="flex-grow">
                          {parcelCount.toLocaleString()}
                        </span>
                      </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-5">
                      <dt className="text-sm font-medium text-gray-500">
                        Average parcel size (Ha)
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0">
                        <span className="flex-grow">
                          {parcelCount === 0
                            ? "-"
                            : (totalArea / parcelCount).toLocaleString()}
                        </span>
                      </dd>
                    </div>
                  </>
                )}
                {Object.keys(bardata).length > 0 &&
                  currentTab === "Land use" && (
                    <Barchart
                      data={bardata}
                      title="Number of parcels by size"
                      xaxis="Size of parcel (Ha)"
                    />
                  )}
                {Object.keys(bardataCrop).length > 0 &&
                  currentTab === "Crop type" && (
                    <Barchart
                      data={bardataCrop}
                      title="Number of parcels by crop type"
                      xaxis="Crop type"
                    />
                  )}
                {currentTab === "Phenological stage" &&
                  (CLIENTS[client]["phenologyModelCrops"].includes(crop) &&
                  Object.keys(bardataPheno).length > 0 ? (
                    <Barchart
                      data={bardataPheno}
                      title="Number of parcels by phenology"
                      xaxis="Phenological Stage"
                    />
                  ) : (
                    <div className="my-4 text-center text-gray-500 text-sm">
                      <h2 className="mt-6 font-semibold">
                        No phenological stage model for this crop available
                      </h2>
                    </div>
                  ))}
                {currentTab === "Land use" && (
                  <Table
                    title={"Regional breakdown"}
                    info={dataForTable}
                    header={[
                      "Region",
                      "Total parcel counts",
                      "Total Area (Ha)",
                      "Mean Area (Ha)",
                    ]}
                  />
                )}
                {currentTab === "Crop type" && (
                  <Table
                    title={"Regional breakdown By Crop"}
                    info={dataByCropForTable}
                    header={[
                      "Region",
                      "Crop",
                      "Total parcel counts",
                      "Total Area (Ha)",
                      "Mean Area (Ha)",
                    ]}
                  />
                )}
                {CLIENTS[client]["phenologyModelCrops"].includes(crop) &&
                  currentTab === "Phenological stage" && (
                    <Table
                      title={"Regional breakdown by phenology"}
                      info={dataByPhenologyForTable}
                      header={[
                        "Region",
                        "Phenology",
                        "Total parcel counts",
                        "Total Area (Ha)",
                        "Mean Area (Ha)",
                      ]}
                    />
                  )}
                {currentTab === "Weather and Indices" &&
                  BarplotWeather(
                    barPrecipitation,
                    barTemperatureDay,
                    barTemperatureNight,
                    DateRangeToggle,
                  )}
                {currentTab === "Weather and Indices" &&
                  BarplotNDVI(bardataNDVI)}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
